import { FiFurnitureService, IFurnitureItem, IColumnItem, IRowItem, IMergedRows, ISlidingDoorItem, IDivideRows } from './../components/fiFurniture/FiFurnitureService';

export class FiPrice {

    static $inject = [
        'fiFurnitureService',
    ];

    constructor(
        private fs: FiFurnitureService,
    ) {
    }
    
    total: number;
    rows_m2: number;
    last_column_m2: number;
    plinth_m2: number;
    total_m2: number;
    doors_m2: number;
    door_m2: number;
    drawer_m2: number;
    counter: number = 0;
    material_filling_m2: number;

    doorHasMirror: boolean;

    slidingDoorsGroup = [];
    slidingDoorsLmdp = [];
    doorsMDFSame = [];
    doorsMDF = [];

    doorsGroup = [];
    doorsLmdp = [];
    doorsLmdpSame = [];
    slidingDoorsLmdpSame = [];
    totalDoorsM2: number = 0;
    totalMergedHeight: number = 0;
    totalMergedRowM2: number = 0;
    rowM2: number = 0;
    newPrice: number = 0;

    // Isskaidyti i smulkesnias funkcijas
    getM2() {

        // Count rows
        this.rows_m2 = 0;
        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                this.rows_m2 = this.rows_m2 + (row.width * this.fs.furniture.depth) + (row.height * this.fs.furniture.depth);

                // Vertikalus atskyrimas
                if (row.inner == 3) {
                    this.rows_m2 = this.rows_m2 + (row.height * this.fs.furniture.depth);
                }

            }
            this.last_column_m2 = column.height * this.fs.furniture.depth;
        }

        // Adding last section
        this.rows_m2 = this.rows_m2 +  this.last_column_m2;

        // Count plinth
        if (this.fs.furniture.plinth.type == 1 || this.fs.furniture.plinth.type == 5) {
            this.plinth_m2 = (this.fs.furniture.width * this.fs.furniture.depth) + (this.fs.furniture.plinth.height * this.fs.furniture.width) + (this.fs.furniture.plinth.height * this.fs.furniture.depth * 2);
        }
        else if (this.fs.furniture.plinth.type == 2) {
            this.plinth_m2 = this.fs.furniture.width * this.fs.furniture.depth;
        }
        else if (this.fs.furniture.plinth.type == 3) {
            this.plinth_m2 = this.fs.furniture.width * this.fs.furniture.depth;
        }
        else if (this.fs.furniture.plinth.type == 4) {
            this.plinth_m2 = 0;
        }

        this.total_m2 = (this.plinth_m2 + this.rows_m2) / 10000;


        if (this.fs.furniture.material_different_sides) {
            if(!this.isLeftMaterialSame()) {
                this.total_m2 = this.total_m2 - this.getLeftM2();
            }
    
            if(!this.isRightMaterialSame()) {
                this.total_m2 = this.total_m2 - this.getRightM2();
            }
    
            if(!this.isTopMaterialSame()) {
                this.total_m2 = this.total_m2 - this.getTopM2();
            }
    
            if(!this.isBottomMaterialSame()) {
                this.total_m2 = this.total_m2 - this.getBottomM2();
            }
        }
        

        return this.total_m2;
        
    }

    isLeftMaterialSame() {
        let same = false;

        if (this.fs.furniture.material_is_mdf) {
            if (this.fs.furniture.material_ls_is_mdf && this.fs.furniture.material_mdf.code == this.fs.furniture.material_ls_mdf.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        else {
            if (!this.fs.furniture.material_ls_is_mdf && this.fs.furniture.material.code == this.fs.furniture.material_ls_lmdp.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        return same;

    }

    isRightMaterialSame() {

        let same = false;

        if (this.fs.furniture.material_is_mdf) {
            if (this.fs.furniture.material_rs_is_mdf && this.fs.furniture.material_mdf.code == this.fs.furniture.material_rs_mdf.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        else {
            if (!this.fs.furniture.material_rs_is_mdf && this.fs.furniture.material.code == this.fs.furniture.material_rs_lmdp.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        return same;

    }

    isTopMaterialSame() {
        let same = false;

        if (this.fs.furniture.material_is_mdf) {
            if (this.fs.furniture.material_ts_is_mdf && this.fs.furniture.material_mdf.code == this.fs.furniture.material_ts_mdf.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        else {
            if (!this.fs.furniture.material_ts_is_mdf && this.fs.furniture.material.code == this.fs.furniture.material_ts_lmdp.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        return same;
    }

    isBottomMaterialSame() {
        let same = false;

        if (this.fs.furniture.material_is_mdf) {
            if (this.fs.furniture.material_bs_is_mdf && this.fs.furniture.material_mdf.code == this.fs.furniture.material_bs_mdf.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        else {
            if (!this.fs.furniture.material_bs_is_mdf && this.fs.furniture.material.code == this.fs.furniture.material_bs_lmdp.code) { 
                same = true; 
            }
            else {  
                same = false; 
            }
        }
        return same;
    }

    getMaterialSideMDFPrice(side: number) {
        if (side == 1) {
            if (this.fs.furniture.material_ls_mdf_gloss == 0) {
                return Number(this.fs.furniture.material_ls_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 1) {
                return Number(this.fs.furniture.material_ls_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 2) {
                return Number(this.fs.furniture.material_ls_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 3) {
                return Number(this.fs.furniture.material_ls_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 4) {
                return Number(this.fs.furniture.material_ls_mdf.price_super_shiny_2_side);
            }
        }
        else if (side == 2) {
            if (this.fs.furniture.material_rs_mdf_gloss == 0) {
                return Number(this.fs.furniture.material_rs_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 1) {
                return Number(this.fs.furniture.material_rs_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 2) {
                return Number(this.fs.furniture.material_rs_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 3) {
                return Number(this.fs.furniture.material_rs_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 4) {
                return Number(this.fs.furniture.material_rs_mdf.price_super_shiny_2_side);
            }
        }
        else if (side == 3) {
            if (this.fs.furniture.material_ts_mdf_gloss == 0) {
                return Number(this.fs.furniture.material_ts_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 1) {
                return Number(this.fs.furniture.material_ts_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 2) {
                return Number(this.fs.furniture.material_ts_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 3) {
                return Number(this.fs.furniture.material_ts_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 4) {
                return Number(this.fs.furniture.material_ts_mdf.price_super_shiny_2_side);
            }
        }
        else if (side == 4) {
            if (this.fs.furniture.material_bs_mdf_gloss == 0) {
                return Number(this.fs.furniture.material_bs_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 1) {
                return Number(this.fs.furniture.material_bs_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 2) {
                return Number(this.fs.furniture.material_bs_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 3) {
                return Number(this.fs.furniture.material_bs_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 4) {
                return Number(this.fs.furniture.material_bs_mdf.price_super_shiny_2_side);
            }
        }
    }

    getMaterialLeftSidePrice() {
        if (this.fs.furniture.material_ls_is_mdf) {

            // If thickness = 0
            if (this.fs.furniture.material_ls_mdf_gloss == 0) {
                return this.getLeftM2() * Number(this.fs.furniture.material_ls_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 1) {
                return this.getLeftM2() * Number(this.fs.furniture.material_ls_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 2) {
                return this.getLeftM2() * Number(this.fs.furniture.material_ls_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 3) {
                return this.getLeftM2() * Number(this.fs.furniture.material_ls_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_ls_mdf_gloss == 4) {
                return this.getLeftM2() * Number(this.fs.furniture.material_ls_mdf.price_super_shiny_2_side);
            }
        }
        else {
            return this.roundM2(this.getLeftM2()) * Number(this.fs.furniture.material_ls_lmdp.price);
        }
    }

    getMaterialRightSidePrice() {
        if (this.fs.furniture.material_rs_is_mdf) {

            // If thickness = 0
            if (this.fs.furniture.material_rs_mdf_gloss == 0) {
                return this.getRightM2() * Number(this.fs.furniture.material_rs_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 1) {
                return this.getRightM2() * Number(this.fs.furniture.material_rs_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 2) {
                return this.getRightM2() * Number(this.fs.furniture.material_rs_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 3) {
                return this.getRightM2() * Number(this.fs.furniture.material_rs_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_rs_mdf_gloss == 4) {
                return this.getRightM2() * Number(this.fs.furniture.material_rs_mdf.price_super_shiny_2_side);
            }
        }
        else {
            return this.roundM2(this.getRightM2()) * Number(this.fs.furniture.material_rs_lmdp.price);
        }

    }

    getMaterialTopSidePrice() {
        if (this.fs.furniture.material_ts_is_mdf) {

            // If thickness = 0
            if (this.fs.furniture.material_ts_mdf_gloss == 0) {
                return this.getTopM2() * Number(this.fs.furniture.material_ts_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 1) {
                return this.getTopM2() * Number(this.fs.furniture.material_ts_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 2) {
                return this.getTopM2() * Number(this.fs.furniture.material_ts_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 3) {
                return this.getTopM2() * Number(this.fs.furniture.material_ts_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_ts_mdf_gloss == 4) {
                return this.getTopM2() * Number(this.fs.furniture.material_ts_mdf.price_super_shiny_2_side);
            }
        }
        else {
            return this.roundM2(this.getTopM2()) * Number(this.fs.furniture.material_ts_lmdp.price);
        }
        
    }

    getMaterialBottomSidePrice() {
        if (this.fs.furniture.material_bs_is_mdf) {

            // If thickness = 0
            if (this.fs.furniture.material_bs_mdf_gloss == 0) {
                return this.getBottomM2() * Number(this.fs.furniture.material_bs_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 1) {
                return this.getBottomM2() * Number(this.fs.furniture.material_bs_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 2) {
                return this.getBottomM2() * Number(this.fs.furniture.material_bs_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 3) {
                return this.getBottomM2() * Number(this.fs.furniture.material_bs_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_bs_mdf_gloss == 4) {
                return this.getBottomM2() * Number(this.fs.furniture.material_bs_mdf.price_super_shiny_2_side);
            }
        }
        else {
            return this.roundM2(this.getBottomM2()) * Number(this.fs.furniture.material_bs_lmdp.price);
        }
        
    }

    getLeftM2() {
        let total_m2 = 0;
        for(const column of this.fs.furniture.columns) {
            total_m2 = column.height * this.fs.furniture.depth;
        }
        return total_m2 / 10000;
    }

    getRightM2() {
        let total_m2 = 0;
        let first = true;
        for(const column of this.fs.furniture.columns) {
            if (first) {
                total_m2 = column.height * this.fs.furniture.depth;    
                first = false;
            }
            
        }
        return total_m2 / 10000;
    }

    getTopM2() {
        let total_m2 = (this.fs.furniture.depth * this.fs.furniture.width);
        return total_m2 / 10000;
    }

    getBottomM2 () {
        
        let total_m2 = 0;

        if (this.fs.furniture.plinth.type == 1 || this.fs.furniture.plinth.type == 5) {
            total_m2 = (this.fs.furniture.width * this.fs.furniture.depth) + (this.fs.furniture.plinth.height * this.fs.furniture.width) + (this.fs.furniture.plinth.height * this.fs.furniture.depth * 2);
        }
        else if (this.fs.furniture.plinth.type == 2) {
            total_m2 = this.fs.furniture.width * this.fs.furniture.depth;
        }
        else if (this.fs.furniture.plinth.type == 3) {
            total_m2 = this.fs.furniture.width * this.fs.furniture.depth;
        }
        else if (this.fs.furniture.plinth.type == 4) {
            total_m2 = 0;
        }
        return total_m2 / 10000;
    }

    getDrawerM2() {
        this.drawer_m2 = 0;
        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 6 || row.inner == 4) {
                    this.drawer_m2 = this.drawer_m2 + ((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth));
                }
                else if (row.inner == 5 || row.inner == 6) {
                    this.drawer_m2 = this.drawer_m2 + (((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth)) / 2);
                    this.drawer_m2 = this.drawer_m2 + (this.fs.furniture.depth * row.height);
                }
                else if (row.inner == 7) {
                    this.drawer_m2 = this.drawer_m2 + ((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth));
                    this.drawer_m2 = this.drawer_m2 + (this.fs.furniture.depth * row.height);
                }
            }
        }

        return this.drawer_m2 / 10000;
    }

    getDrawerPrice() {
        this.drawer_m2 = 0;
        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 7 || row.inner == 4) {
                    this.drawer_m2 = this.drawer_m2 + ((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth));
                }
                else if (row.inner == 5 || row.inner == 6) {
                    this.drawer_m2 = this.drawer_m2 + (((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth)) / 2);
                    this.drawer_m2 = this.drawer_m2 + (this.fs.furniture.depth * row.height);
                }
                else if (row.inner == 7) {
                    this.drawer_m2 = this.drawer_m2 + ((row.width * row.height * 2) + (this.fs.furniture.depth * row.height * 2) + (row.width * this.fs.furniture.depth));
                    this.drawer_m2 = this.drawer_m2 + (this.fs.furniture.depth * row.height);
                }
            }
        }

        return this.drawer_m2 / 10000 * Number(this.fs.furniture.material.price);
    }

    getDoorsM2() {
        this.doors_m2 = 0;
        this.door_m2 = 0;
        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.panel.id == this.fs.furniture.material.id && (row.is_mdf == 0 || row.is_mdf == false)) {
                        if (row.merged) {
                            this.door_m2 = row.height * row.width;
                            // row.merged_with.forEach((r: IMergedRows) => {
                                // this.door_m2 = this.door_m2 + (Number(r.row_height) * Number(r.row_width));
                            // });
    
                            for(const mr of row.merged_with) {
    
                                for(const cr of column.rows) {
                                    if (cr.row_id == mr.row_id) {
                                        this.door_m2 = this.door_m2 + (Number(cr.height) * Number(cr.width));
                                    }
                                }
                            }
    
                            this.doors_m2 = this.doors_m2 + (this.door_m2 / 10000);
                            this.door_m2 = 0;
                        }
                        else {
                            this.doors_m2 = this.doors_m2 + ((row.height * row.width) / 10000);
                        }
                    }
                    
                }
                
            }
        }
        return this.doors_m2;
    }

    getDoorsM2Rounded() {
        let total_m2 = this.getDoorsM2();

        if (total_m2 < 1) {
            total_m2 = 1.5;
        }
        else if (total_m2 > 1 && total_m2 < 2) {
            total_m2 = 3;
        }
        else if (total_m2 > 2 && total_m2 < 3.5) {
            total_m2 = 4.5;
        }
        else if (total_m2 > 3.5 && total_m2 < 5) {
            total_m2 = 6;
        }
        else if (total_m2 > 5 && total_m2 < 6) {
            total_m2 = 7.5;
        }
        else if (total_m2 > 6 && total_m2 < 12.5) {
            total_m2 = total_m2 * 1.25;
        }
        else if (total_m2 > 12.5) {
            total_m2 = total_m2 * 1.2;
        }

        return total_m2;
    }

    getDrawerM2Rounded() {
        let total_m2 = this.getDrawerM2();

        if (total_m2 < 1) {
            total_m2 = 1.5;
        }
        else if (total_m2 > 1 && total_m2 < 2) {
            total_m2 = 3;
        }
        else if (total_m2 > 2 && total_m2 < 3.5) {
            total_m2 = 4.5;
        }
        else if (total_m2 > 3.5 && total_m2 < 5) {
            total_m2 = 6;
        }
        else if (total_m2 > 5 && total_m2 < 6) {
            total_m2 = 7.5;
        }
        else if (total_m2 > 6 && total_m2 < 12.5) {
            total_m2 = total_m2 * 1.25;
        }
        else if (total_m2 > 12.5) {
            total_m2 = total_m2 * 1.2;
        }

        return total_m2;
    }

    getDoorsPrice() {
        this.doors_m2 = 0;
        this.door_m2 = 0;
        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.merged) {

                        this.door_m2 = row.height * row.width;

                        for(const mr of row.merged_with) {

                            for(const cr of column.rows) {
                                if (cr.row_id == mr.row_id) {
                                    this.door_m2 = this.door_m2 + (Number(cr.height) * Number(cr.width));
                                }
                            }
                        }

                       //  row.merged_with.forEach((r: IMergedRows) => {

                          //  this.door_m2 = this.door_m2 + (Number(r.row_height) * Number(r.row_width));

                        // });

                        this.doors_m2 = this.doors_m2 + (this.door_m2 / 10000 * Number(row.panel.price));
                        this.door_m2 = 0;
                        
                    }
                    else {
                        
                        this.doors_m2 = this.doors_m2 + ((row.height * row.width) / 10000 *  Number(row.panel.price));
                    }
                }
                
            }
        }
        return this.doors_m2;
    }

    getDoorsOfDifferentLmdpPrice() {

        this.doorsGroup = [];
        this.doorsLmdp = [];
        this.counter = 0;
        this.totalDoorsM2 = 0;
        this.totalMergedRowM2 = 0;
        this.rowM2 = 0;
        this.newPrice = 0;

        this.doorsLmdpSame = [];

        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.panel.id != this.fs.furniture.material.id) {
                        this.doorsGroup.push(row);
                    }
                }
            }
        }

        //const distinctThings = this.doorsGroup.filter((thing, i, arr) => {
            //return arr.indexOf(arr.find(t => t.panel.id === thing.panel.id)) === i;
        //});


        this.doorsLmdpSame = this.doorsGroup.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => t.panel.id === thing.panel.id)) === i;
        });

        //console.log('doorsLmdpSame: ' + JSON.parse(JSON.stringify(this.doorsLmdpSame)));

        for(const row of this.doorsLmdpSame) {
            
            let totalHeight = 0;
            this.rowM2 = 0;
            

            for(const row2 of this.doorsGroup) {
                if (row.panel.id == row2.panel.id) {

                    if(row.is_mdf == 0) {
                        totalHeight = totalHeight + row2.height;
                        this.rowM2 = this.rowM2 + ((row2.height * row2.width) / 10000);
    
                        this.totalMergedHeight = 0;
                        this.totalMergedRowM2 = 0;
    
                        if (row2.merged) {
                            for(const mr of row2.merged_with) {
    
                                this.totalMergedHeight = this.totalMergedHeight + mr.row_height;
    
                                this.totalMergedRowM2 = this.totalMergedRowM2 + ((mr.row_height * mr.row_width) / 10000);
                                // totalHeight = totalHeight + mr.row_height;
    
                                //for(const column of this.fs.furniture.columns) {
                                    //for(const cr of column.rows) {
                                      //  if (cr.row_id == mr.row_id) {
                                       //     totalHeight = totalHeight + cr.height;
                                      //  }
                                   // }
                                //}
                            }
                        }
    
                        this.rowM2 = this.rowM2 + this.totalMergedRowM2;
                        totalHeight = totalHeight + this.totalMergedHeight;
                    }
                    
                }
            };

            this.doorsLmdp.push({
                name: row.panel.name,
                price: Number(row.panel.price),
                height: totalHeight,
                width: row.width,
                panelId: row.panel.id,
                rounded: Number(Math.ceil(totalHeight / this.fs.furniture.height)),
                m2: (totalHeight * row.height) / 10000,
                totalM2: this.rowM2,
                newPrice: Number(row.panel.price) * Number(this.getM2DoorRounded(this.rowM2)),
                finalPrice: Number(row.panel.price) * Number(this.getM2DoorRounded((row.width * totalHeight) / 10000))

            });

            totalHeight = 0;
        }

        // console.log(this.doorsLmdp);

        for(const row of this.doorsLmdp) {
            if (this.fs.furniture.material.id != row.panelId) {
                this.counter = this.counter + row.finalPrice;
                this.totalDoorsM2 = this.totalDoorsM2 + row.totalM2;
                this.newPrice = this.newPrice + row.newPrice;
            }
            
        }

        // console.log('newPrice: ' + this.newPrice);

        // return this.counter;
        return this.newPrice;
    }

    getDoorsOfDifferentMDFM2() {
        this.doorsGroup = [];
        this.doorsMDF = [];
        this.counter = 0;
        this.totalDoorsM2 = 0;
        this.totalMergedRowM2 = 0;
        this.rowM2 = 0;
        this.newPrice = 0;

        this.doorsMDFSame = [];

        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.is_mdf == 1) {
                        this.doorsGroup.push(row);
                    }
                    
                }
            }
        }

        //const distinctThings = this.doorsGroup.filter((thing, i, arr) => {
            //return arr.indexOf(arr.find(t => t.panel.id === thing.panel.id)) === i;
        //});


        this.doorsMDFSame = this.doorsGroup.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => t.mdf.id === thing.mdf.id)) === i;
        });

        for(const row of this.doorsMDFSame) {
            
            let totalHeight = 0;
            this.rowM2 = 0;
            

            for(const row2 of this.doorsGroup) {
                if (row.mdf.id == row2.mdf.id) {

                    totalHeight = totalHeight + row2.height;
                    this.rowM2 = this.rowM2 + ((row2.height * row2.width) / 10000);

                    this.totalMergedHeight = 0;
                    this.totalMergedRowM2 = 0;

                    if (row2.merged) {
                        for(const mr of row2.merged_with) {

                            this.totalMergedHeight = this.totalMergedHeight + mr.row_height;

                            this.totalMergedRowM2 = this.totalMergedRowM2 + ((mr.row_height * mr.row_width) / 10000);
                            // totalHeight = totalHeight + mr.row_height;

                            //for(const column of this.fs.furniture.columns) {
                                //for(const cr of column.rows) {
                                  //  if (cr.row_id == mr.row_id) {
                                   //     totalHeight = totalHeight + cr.height;
                                  //  }
                               // }
                            //}
                        }
                    }

                    this.rowM2 = this.rowM2 + this.totalMergedRowM2;
                    totalHeight = totalHeight + this.totalMergedHeight;
                }
            };

            this.doorsMDF.push({

                totalM2: Number(this.getM2DoorRounded(this.rowM2))

            });

            totalHeight = 0;
        }

        // console.log(this.doorsLmdp);

        for(const row of this.doorsMDF) {
            this.totalDoorsM2 = this.totalDoorsM2 + row.totalM2;
        }

        // return this.counter;
        return this.totalDoorsM2;
    }

    getDoorsOfDifferentLmdpM2() {

        this.doorsGroup = [];
        this.doorsLmdp = [];
        this.counter = 0;
        this.totalDoorsM2 = 0;
        this.totalMergedRowM2 = 0;
        this.rowM2 = 0;
        this.newPrice = 0;

        this.doorsLmdpSame = [];

        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.panel.id != this.fs.furniture.material.id) {
                        this.doorsGroup.push(row);
                    }
                }
            }
        }

        //const distinctThings = this.doorsGroup.filter((thing, i, arr) => {
            //return arr.indexOf(arr.find(t => t.panel.id === thing.panel.id)) === i;
        //});


        this.doorsLmdpSame = this.doorsGroup.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => t.panel.id === thing.panel.id)) === i;
        });

        for(const row of this.doorsLmdpSame) {
            
            let totalHeight = 0;
            this.rowM2 = 0;
            

            for(const row2 of this.doorsGroup) {
                if (row.panel.id == row2.panel.id) {

                    totalHeight = totalHeight + row2.height;
                    this.rowM2 = this.rowM2 + ((row2.height * row2.width) / 10000);

                    this.totalMergedHeight = 0;
                    this.totalMergedRowM2 = 0;

                    if (row2.merged) {
                        for(const mr of row2.merged_with) {

                            this.totalMergedHeight = this.totalMergedHeight + mr.row_height;

                            this.totalMergedRowM2 = this.totalMergedRowM2 + ((mr.row_height * mr.row_width) / 10000);
                            // totalHeight = totalHeight + mr.row_height;

                            //for(const column of this.fs.furniture.columns) {
                                //for(const cr of column.rows) {
                                  //  if (cr.row_id == mr.row_id) {
                                   //     totalHeight = totalHeight + cr.height;
                                  //  }
                               // }
                            //}
                        }
                    }

                    this.rowM2 = this.rowM2 + this.totalMergedRowM2;
                    totalHeight = totalHeight + this.totalMergedHeight;
                }
            };

            this.doorsLmdp.push({

                totalM2: Number(this.getM2DoorRounded(this.rowM2))

            });

            totalHeight = 0;
        }

        // console.log(this.doorsLmdp);

        for(const row of this.doorsLmdp) {
            if (this.fs.furniture.material.id != row.panelId) {
                this.totalDoorsM2 = this.totalDoorsM2 + row.totalM2;
            }
            
        }

        // return this.counter;
        return this.totalDoorsM2;
    }


    getMaterialMDFPrice() {
        if (this.fs.furniture.material_mdf_gloss == 0) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return Number(this.fs.furniture.material_mdf.price_matte_2_side);
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(this.fs.furniture.material_mdf.price_matte_2_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(this.fs.furniture.material_mdf.price_matte_2_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 1) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return Number(this.fs.furniture.material_mdf.price_matte_1_side);
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(this.fs.furniture.material_mdf.price_matte_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(this.fs.furniture.material_mdf.price_matte_1_side) + 5.57;
            }

            
        }
        else if (this.fs.furniture.material_mdf_gloss == 2) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return Number(this.fs.furniture.material_mdf.price_shiny_1_side);
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(this.fs.furniture.material_mdf.price_shiny_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(this.fs.furniture.material_mdf.price_shiny_1_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 3) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_1_side);
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_1_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 4) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_2_side);
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_2_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(this.fs.furniture.material_mdf.price_super_shiny_2_side) + 5.57;
            }

            
        }
    }

    getM2RoundedPrice() {

        if (this.fs.furniture.material_is_mdf) {
            // MDF
            return this.getM2Total() * this.getMaterialMDFPrice();
        }
        else {
            // LMDP
            return this.getM2Rounded() * Number(this.fs.furniture.material.price);
        }
    }

    getM2SlidingDoorsFurnitureRounded() {
        let total_m2 = this.getM2() + this.getDrawerM2() + this.getSlidingDoorsM2();

        if (total_m2 < 1) {
            total_m2 = 1.5;
        }
        else if (total_m2 >= 1 && total_m2 < 2) {
            total_m2 = 3;
        }
        else if (total_m2 >= 2 && total_m2 <= 3.5) {
            total_m2 = 4.5;
        }
        else if (total_m2 >= 3.5 && total_m2 <= 5) {
            total_m2 = 6;
        }
        else if (total_m2 >= 5 && total_m2 <= 6) {
            total_m2 = 7.5;
        }
        else if (total_m2 >= 6 && total_m2 < 12.5) {
            total_m2 = total_m2 * 1.25;
        }
        else if (total_m2 >= 12.5) {
            total_m2 = total_m2 * 1.2;
        }

        return total_m2;
    }

    getFrameworkM2() {

        let total = 0;
        if (this.fs.furniture.material_different_sides) {
            total = this.roundM2(this.getTopM2()) + this.roundM2(this.getBottomM2()) + this.roundM2(this.getLeftM2()) + this.roundM2(this.getRightM2());
        }
        return total;

    }

    getAssemblyPrice() {
        if (!this.fs.furniture.collect_myself) {
            if (this.fs.furniture.type == 2) {
                if ((this.getM2SlidingDoorsFurnitureRounded() + this.getFrameworkM2()) < 3) {
                    return 3 * 10.89;
                }
                else {
                    return (this.getM2SlidingDoorsFurnitureRounded() + this.getFrameworkM2()) * 10.89;
                }
            }
            else {
                if ((this.getM2Rounded() + this.getDoorsOfDifferentLmdpM2() + this.getDoorsOfDifferentMDFM2() + this.getFrameworkM2()) < 3) {
                    return 3 * 10.89;
                }
                else {
                    return (this.getM2Rounded() + this.getDoorsOfDifferentLmdpM2() + this.getDoorsOfDifferentMDFM2() + this.getFrameworkM2()) * 10.89;
                }
            }
        }
        else {
            return 0;
        }
    }

    getCarryPrice() {
        if (this.fs.furniture.type == 2) {
            return (this.getM2SlidingDoorsFurnitureRounded() + this.getFrameworkM2()) * 2.20;
        }
        else {
            return (this.getM2Rounded() + this.getDoorsOfDifferentLmdpM2() + this.getDoorsOfDifferentMDFM2() + this.getFrameworkM2()) * 2.20;
        }
    }

    getM2Total() {
        if (this.fs.furniture.type == 2) {
            // 2023-03-22   ->   return this.getM2() + this.getDrawerM2() + this.getSlidingDoorsM2();
            return this.getM2() + this.getDrawerM2();
        }
        else {
            return this.getM2() + this.getDrawerM2() + this.getDoorsM2();
        }

        // return this.getM2() + this.getDoorsM2() + this.getDrawerM2() + this.getSlidingDoorsM2();
    }

    roundM2(m2: number) {
        if (m2 < 1) {
            m2 = 1.5;
        }
        else if (m2 >= 1 && m2 < 2) {
            m2 = 3;
        }
        else if (m2 >= 2 && m2 <= 3.5) {
            m2 = 4.5;
        }
        else if (m2 >= 3.5 && m2 <= 5) {
            m2 = 6;
        }
        else if (m2 >= 5 && m2 <= 6) {
            m2 = 7.5;
        }
        else if (m2 >= 6 && m2 < 12.5) {
            m2 = m2 * 1.25;
        }
        else if (m2 >= 12.5) {
            m2 = m2 * 1.2;
        }
        return m2;
    }


    getM2Rounded() {

        let total_m2 = this.getM2Total();

        if (total_m2 < 1) {
            total_m2 = 1.5;
        }
        else if (total_m2 >= 1 && total_m2 < 2) {
            total_m2 = 3;
        }
        else if (total_m2 >= 2 && total_m2 <= 3.5) {
            total_m2 = 4.5;
        }
        else if (total_m2 >= 3.5 && total_m2 <= 5) {
            total_m2 = 6;
        }
        else if (total_m2 >= 5 && total_m2 <= 6) {
            total_m2 = 7.5;
        }
        else if (total_m2 >= 6 && total_m2 < 12.5) {
            total_m2 = total_m2 * 1.25;
        }
        else if (total_m2 >= 12.5) {
            total_m2 = total_m2 * 1.2;
        }

        return total_m2;
    }

    getM2DoorRounded(doorM2: number) {
        let total_m2 = doorM2;

        if (total_m2 < 1) {
            total_m2 = 1.5;
        }
        else if (total_m2 >= 1 && total_m2 <= 2) {
            total_m2 = 3;
        }
        else if (total_m2 >= 2 && total_m2 <= 3.5) {
            total_m2 = 4.5;
        }
        else if (total_m2 >= 3.5 && total_m2 <= 5) {
            total_m2 = 6;
        }
        else if (total_m2 >= 5 && total_m2 <= 6) {
            total_m2 = 7.5;
        }
        else if (total_m2 >= 6 && total_m2 <= 12.5) {
            total_m2 = total_m2 * 1.25;
        }
        else if (total_m2 >= 12.5) {
            total_m2 = total_m2 * 1.2;
        }

        return total_m2;
    }

    getSlidingDoorsM2() {
        this.counter = 0;
        this.door_m2 = 0;

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                  this.counter = this.counter + ((row.height * row.width) / 10000);

            });
        });

        return this.counter;
    }

    getSlidingDoorsPrice() {

        this.counter = 0;
        this.door_m2 = 0;

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                this.door_m2 = (row.height * row.width) / 10000;
                this.counter = this.counter + (this.getM2DoorRounded(this.door_m2) *  Number(row.panel.price));

            });
        });

        return this.counter;
    }

    

    getHandlesCount() {

        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {

                if (row.outer != 0) {
                    if (row.handleType == 1 || row.handleType == 2) {
                        this.counter = this.counter + 1;
    
                        if (row.outer == 3) {
                            this.counter = this.counter + 1;
                        }
                    }
                }
                
            });
        });

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                if (row.handle_type == 1 || row.handle_type == 2) {
                    this.counter = this.counter + 1;
                }

            });
        });

        return this.counter;
    }
    getHandlesPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {

                if (row.outer != 0) {

                    if (row.handleType == 1) {
                        this.counter = this.counter + Number(row.handle.price);

                        if (row.outer == 3) {
                            this.counter = this.counter + Number(row.handle.price);
                        }
                    }
                    else if (row.handleType == 2) {
                        this.counter = this.counter + Number(row.handle_internal.price);

                        if (row.outer == 3) {
                            this.counter = this.counter + Number(row.handle_internal.price);
                        }
                    }

                }
                
            });
        });

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                if (row.handle_type == 1) {
                    this.counter = this.counter + Number(row.handle.price);
                }
                else if (row.handle_type == 2) {
                    this.counter = this.counter + Number(row.handle_internal.price);
                }

            });
        });

        return this.counter;
    }

    getClothesHangersCount() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.inner == 1) {
                    this.counter = this.counter + row.width;
                }
                else if (row.inner == 2) {
                    this.counter = this.counter + row.width + row.width;
                }
            });
        });

        this.counter = this.counter / 100;

        return this.counter;
    }

    getClothesHangersPrice() {

        if (this.fs.furniture.quality == 1) {
            return this.getClothesHangersCount() * 10;
        }
        else if (this.fs.furniture.quality == 2) {
            return this.getClothesHangersCount() * 10;
        }
        else if (this.fs.furniture.quality == 3) {
            return this.getClothesHangersCount() * 15.92;
        }

        return this.counter;
    }

    getLegsCount() {

        this.counter = 0;
        if (this.fs.furniture.plinth.type == 2) {
            if (this.fs.furniture.width < 100) {
                this.counter = 4;
            }
            else if (this.fs.furniture.width > 100 && this.fs.furniture.width < 200) {
                this.counter = 6;
            }
            else if (this.fs.furniture.width > 200 && this.fs.furniture.width < 300) {
                this.counter = 8;
            }
            else if (this.fs.furniture.width > 300 && this.fs.furniture.width < 400) {
                this.counter = 10;
            }
            else if (this.fs.furniture.width > 400 && this.fs.furniture.width < 500) {
                this.counter = 12;
            }
            else if (this.fs.furniture.width > 500 && this.fs.furniture.width < 600) {
                this.counter = 14;
            }
        }
        else {
            this.counter = 0;
        }

        return this.counter;

    }

    getRearWallCount() {
        return Math.ceil((this.fs.furniture.width * this.fs.furniture.height / 10000) / (2.85 * 2.07));
    }

    getRearWallPrice() {
        return this.getRearWallCount() * 22.69;
    }

    getRejectsCount() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {

                if (row.outer != 0) {
                    if (row.handleType == 3) {
                        this.counter = this.counter + 1;
    
                        if (row.outer == 3) {
                            this.counter = this.counter + 1;
                        }
                    }
                }
                
            });
        });

        return this.counter;
    }

    getRejectsPrice() {
        this.counter = 0;
        return this.getRejectsCount() * 5;
    }

    getLegsPrice() {
        if (this.fs.furniture.plinth.type == 2) {
            return this.getLegsCount() * Number(this.fs.furniture.plinth.legs.price);
        }
        else {
            return 0;
        }
    }

    
    getDrawersCount() {

        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.inner == 4 || row.inner == 5 || row.inner == 6 || row.outer == 6) {
                    this.counter = this.counter + 1;
                }
                else if (row.inner == 7) {
                    this.counter = this.counter + 2;
                }
            });
        });

        return this.counter;
    }

    getDrawersPrice() {

        this.counter = 0;
        let price = 0;
        if (this.fs.furniture.quality == 1) {
            price = 4;
        }
        else if (this.fs.furniture.quality == 2) {
            price = 11;
        }
        else if (this.fs.furniture.quality == 3) {
            price = 26;
        }

        if (this.fs.furniture.depth < 65) {
            return this.getDrawersCount() * price;
        }
        else {
            return this.getDrawersCount() * price * 1.5;
        }
    }

    getDoorsSideCount() {
        //console.log(this.fs.furniture);

        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3) {

                    if (row.merged) {

                        let total = row.height;
                        row.merged_with.forEach((r: IMergedRows) => {
                            total = Number(total) + Number(r.row_height);
                            // console.log(r.row_height);
                        });

                        
                        if (total < 80) {
                            this.counter = this.counter + 2;
                            if (row.outer == 3) {
                                this.counter = this.counter + 2;
                            }
                        }
                        else if (total > 80 && total < 170) {
                            this.counter = this.counter + 3;
                            if (row.outer == 3) {
                                this.counter = this.counter + 3;
                            }
                        }
                        else if (total > 170) {
                            this.counter = this.counter + 4;
                            if (row.outer == 3) {
                                this.counter = this.counter + 4;
                            }
                        }

                        total = 0;
                    }
                    else {
                        if (row.height < 80) {
                            this.counter = this.counter + 2;
                            if (row.outer == 3) {
                                this.counter = this.counter + 2;
                            }
                        }
                        else if (row.height > 80 && row.height < 170) {
                            this.counter = this.counter + 3;
                            if (row.outer == 3) {
                                this.counter = this.counter + 3;
                            }
                        }
                        else if (row.height > 170) {
                            this.counter = this.counter + 4;
                            if (row.outer == 3) {
                                this.counter = this.counter + 4;
                            }
                        }
                    }
                }
            });
        });

        return this.counter;
    }

    getDoorsSidePrice() {

        if (this.fs.furniture.quality == 1) {
            return this.getDoorsSideCount() * 0.8;
        }
        else if (this.fs.furniture.quality == 2) {
            return this.getDoorsSideCount() * 2.45;
        }
        else if (this.fs.furniture.quality == 3) {
            return this.getDoorsSideCount() * 3.86;
        }

    }

    getDoorsHingesCount() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.outer == 4 || row.outer == 5) {
                    if (row.width < 80) {
                        this.counter = this.counter + 2;
                    }
                    else if (row.width > 80 && row.width < 170) {
                        this.counter = this.counter + 3;
                    }
                    else if (row.width > 170) {
                        this.counter = this.counter + 4;
                    }
                }
            });
        });

        return this.counter;

    }

    getEdgeCount() {
        this.counter = 0;
        let lastColumnHeight = 0;

        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                
                if (row.is_mdf != 1) {
                    this.counter = this.counter + row.width + row.height;
                    if(row.inner == 3 || row.inner == 5 || row.inner == 6 || row.inner == 7) {
                        this.counter = this.counter + row.height;
                    }
                }
                
            });
            lastColumnHeight = column.height;
        });

        if (this.fs.furniture.material_is_mdf && !this.fs.furniture.material_different_sides) {
            return 0;
        }

        // if (this.fs.furniture.material_different_sides) {
            if (this.fs.furniture.material_ts_is_mdf) {
                this.counter = this.counter + 0;
            }
            else {
                // Stogas
                this.counter = this.counter + (this.fs.furniture.depth * 2) + (this.fs.furniture.width * 2);
            }
            
            if (this.fs.furniture.material_rs_is_mdf) {
                this.counter = this.counter + 0;
            }
            else {
                // Paskutinis stulpelis
                this.counter = this.counter + lastColumnHeight;
            }
            
            if (this.fs.furniture.material_bs_is_mdf) {
                this.counter = this.counter + 0;
            }
            else {
                // Apacia
                if (this.fs.furniture.plinth.type == 1 || this.fs.furniture.plinth.type == 5) {
                    this.counter = this.counter + (this.fs.furniture.width * 2) + (this.fs.furniture.plinth.height * 2);
                    
                }
                else if (this.fs.furniture.plinth.type == 2) {
                    this.counter = this.counter + this.fs.furniture.width;
                }
                else if (this.fs.furniture.plinth.type == 3) {
                    this.counter = this.counter + this.fs.furniture.width;
                }
            }

        // }
        this.counter = this.counter / 100;

        return this.counter;
    }

    getEdgePrice() {
        return this.getEdgeCount() * Number(this.fs.furniture.material.edge_price_thin);
    }

    getGluePrice() {
        return this.getEdgeCount() * 1.69;
    }

    getEdgeThickPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {

                if (row.is_mdf != 1) {
                    if(row.outer == 1 || row.outer == 2 || row.outer == 4 || row.outer == 5) {
                        this.counter = this.counter + (((row.width * 2) + (row.height * 2)) / 100) * Number(row.panel.edge_price_thick);
                    }
                    else if (row.outer == 3) {
                        this.counter = this.counter + (((row.width * 2) + (row.height * 2) + (row.height * 2)) / 100) * Number(row.panel.edge_price_thick);
                    }
                    else if (row.outer == 6) {
                        this.counter = this.counter + (((row.width * 2) + (row.height * 2) + (this.fs.furniture.depth * 2) + row.width) / 100) * Number(row.panel.edge_price_thick);
                    }
                }
                
                    if(row.inner == 4) {
                        this.counter = this.counter + (((row.width * 2) + (row.height * 2) + (this.fs.furniture.depth * 2) + row.width) / 100) * Number(this.fs.furniture.material.edge_price_thin);
                    }
                    else if (row.inner == 5 || row.inner == 6) {
                        this.counter = this.counter + ((((row.width * 2) + (row.height * 2) + (this.fs.furniture.depth * 2) + row.width) / 100) / 2) * Number(this.fs.furniture.material.edge_price_thin);
                    }
                    else if (row.inner == 7) {
                        this.counter = this.counter + (((((row.width * 2) + (row.height * 2) + (this.fs.furniture.depth * 2) + row.width) / 100) / 2) * Number(this.fs.furniture.material.edge_price_thin) * 2);
                    }
                

                
            });
        });

        this.counter = this.counter * 1.69;

        return this.counter;
    }

    getDoorsHingesPrice() {
        if (this.fs.furniture.quality == 1) {
            return this.getDoorsHingesCount() * 0.8;
        }
        else if (this.fs.furniture.quality == 2) {
            return this.getDoorsHingesCount() * 2.45;
        }
        else if (this.fs.furniture.quality == 3) {
            return this.getDoorsHingesCount() * 3.86;
        }
    }



    getDoorsDownCount() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.outer == 5) {
                    this.counter = this.counter + 1;
                }
            });
        });

        return this.counter;
    }

    getDoorsDownPrice() {
        this.counter = 0;
        let price = 0;
        if (this.fs.furniture.quality == 1) {
            price = 3.01;
        }
        else if (this.fs.furniture.quality == 2) {
            price = 8.42;
        }
        else if (this.fs.furniture.quality == 3) {
            price = 33.11;
        }

        if (this.fs.furniture.depth < 60) {
            return this.getDoorsDownCount() * price;
        }
        else {
            return this.getDoorsDownCount() * price * 2;
        }
    }

    getDoorsUpCount() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                if (row.outer == 4) {
                    this.counter = this.counter + 1;
                }
            });
        });

        return this.counter;
    }

    getDoorsUpPrice() {

        this.counter = 0;
        let price = 0;
        if (this.fs.furniture.quality == 1) {
            price = 3.02;
        }
        else if (this.fs.furniture.quality == 2) {
            price = 3.02;
        }
        else if (this.fs.furniture.quality == 3) {
            price = 23.38;
        }

        if (this.fs.furniture.depth < 60) {
            return this.getDoorsUpCount() * price;
        }
        else {
            return this.getDoorsUpCount() * price * 2;
        }

    }

    getKnobPrice() {
        this.counter = 0;

        if (this.fs.furniture.type == 2 && this.fs.furniture.rails == 1 && this.fs.furniture.sliding_door_profiles) {

            this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {

                if (door.width <= 55) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_0_55);
                }
                else if (door.width >= 55 && door.width <= 60) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 60 && door.width <= 65) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 65 && door.width <= 70) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 70 && door.width <= 75) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 75 && door.width <= 80) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 80 && door.width <= 85) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 85 && door.width <= 90) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 90 && door.width <= 95) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 95 && door.width <= 100) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 100 && door.width <= 105) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 105 && door.width <= 110) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 110 && door.width <= 115) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
                else if (door.width >= 115 && door.width <= 120) {
                    this.counter = this.counter + Number(this.fs.furniture.knob.price_55_60);
                }
            });

        }
        else {
            this.counter = 0;
        }
        
        return this.counter;
    }

    getKnobDividePrice() {

        if (this.fs.furniture.type == 2 && this.fs.furniture.rails != 1) {
            return 0;
        }
        else {
            if (!this.fs.furniture.knob) {
                return 0;
            }
            else {
                return Number(this.getKnobDivideCount()) * Number(this.fs.furniture.knob.price_division);
            }
        }
    }

    getKnobDivideCount() {
        this.counter = 0;

        if (this.fs.furniture.type == 2 && this.fs.furniture.rails == 1) {

            this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {

                if (door.divide == 0) {
                    this.counter = this.counter + 0;
                }
                else if (door.divide == 1) {
                    this.counter = this.counter + 1;
                }
                else if (door.divide == 2) {
                    this.counter = this.counter + 2;
                }
                else if (door.divide == 3) {
                    this.counter = this.counter + 3;
                }
                else if (door.divide == 4) {
                    this.counter = this.counter + 2;
                }
                else if (door.divide == 5) {
                    this.counter = this.counter + 2;
                }
            });

        }
        else {
            this.counter = 0;
        }
        
        return this.counter;
    }

    getRoundedOneDoorM2(width: number) {
        
        this.counter = 0;

        if (width < 50) {
            this.counter = this.counter + 1.5;
        }
        else if (width >= 50 && width < 100) {
            this.counter = this.counter + 3;
        }
        else if (width >= 100) {
            this.counter = this.counter + 6;
        }

        return this.counter;

    }
    

    getSlidingDoorsRowMDFPrice(row: IDivideRows) {

        if (row.mdf_gloss == 0) {
            return Number(row.mdf.price_matte_2_side);
           
        }
        else if (row.mdf_gloss == 1) {
            return Number(row.mdf.price_matte_1_side);
        }
        else if (row.mdf_gloss == 2) {
            return Number(row.mdf.price_shiny_1_side);
        }
        else if (row.mdf_gloss == 3) {
            return Number(row.mdf.price_super_shiny_1_side);
        }
        else if (row.mdf_gloss == 4) {
            return Number(row.mdf.price_super_shiny_2_side);
           
        }
    }

    getRowMDFPrice(row: IRowItem) {


        if (row.mdf_gloss == 0) {
            if (row.mdf_thickness == 0) {
                return Number(row.mdf.price_matte_2_side);
            }
            else if (row.mdf_thickness == 1) {
                return Number(row.mdf.price_matte_2_side) + 4;
            }
            else if (this.fs.furniture.activeRow.mdf_thickness == 2) {
                return Number(row.mdf.price_matte_2_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 1) {
            if (row.mdf_thickness == 0) {
                return Number(row.mdf.price_matte_1_side);
            }
            else if (row.mdf_thickness == 1) {
                return Number(row.mdf.price_matte_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(row.mdf.price_matte_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 2) {
            if (row.mdf_thickness == 0) {
                return Number(row.mdf.price_shiny_1_side);
            }
            else if (row.mdf_thickness == 1) {
                return Number(row.mdf.price_shiny_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(row.mdf.price_shiny_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 3) {
            if (row.mdf_thickness == 0) {
                return Number(row.mdf.price_super_shiny_1_side);
            }
            else if (row.mdf_thickness == 1) {
                return Number(row.mdf.price_super_shiny_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(row.mdf.price_super_shiny_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 4) {
            if (row.mdf_thickness == 0) {
                return Number(row.mdf.price_super_shiny_2_side);
            }
            else if (row.mdf_thickness == 1) {
                return Number(row.mdf.price_super_shiny_2_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(row.mdf.price_super_shiny_2_side) + 5.57;
            }
        }
    }



    getSlidingDoorsMDFPrice() {
        this.doors_m2 = 0;
        this.door_m2 = 0;

        this.counter = 0;

        for(const door of this.fs.furniture.slidingDoors) {

            for(const row of door.divideRows) {


                if (row.panel_type == 2) {
                    this.doors_m2 = this.doors_m2 + ((row.height * row.width) / 10000);
                    this.counter = this.counter + (this.doors_m2 * this.getSlidingDoorsRowMDFPrice(row));
                }
                
                this.doors_m2 = 0;
                
            }
        }

    
        return this.counter;
    }

    getMDFPrice() {
        this.doors_m2 = 0;
        this.door_m2 = 0;

        this.counter = 0;

        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.is_mdf == 1) {

                        if (row.merged) {
                            this.door_m2 = row.height * row.width;

                            for(const mr of row.merged_with) {
    
                                for(const cr of column.rows) {
                                    if (cr.row_id == mr.row_id) {
                                        this.door_m2 = this.door_m2 + (Number(cr.height) * Number(cr.width));
                                    }
                                }
                            }
    
                            this.doors_m2 = this.doors_m2 + (this.door_m2 / 10000);
                            this.door_m2 = 0;
                        }
                        else {
                            this.doors_m2 = this.doors_m2 + ((row.height * row.width) / 10000);
                        }

                        this.counter = this.counter + (this.doors_m2 * this.getRowMDFPrice(row));

                    }
                }
                this.doors_m2 = 0;
                
            }
        }

        return this.counter;
    }

    getMaterialFillingsPrice() {
        this.counter = 0;
        this.material_filling_m2 = 0;

        if (this.fs.furniture.material_is_mdf && this.fs.furniture.material_has_fillings) {

            this.material_filling_m2 = (this.fs.furniture.height * this.fs.furniture.depth) / 10000;
            return this.material_filling_m2 * 2 * Number(this.fs.furniture.material_mdf_filling.price_items);

        }
        else {
            return 0;
        }
    }

    getFillingsPrice() {

        this.doors_m2 = 0;
        this.door_m2 = 0;

        this.counter = 0;

        for(const column of this.fs.furniture.columns) {

            for(const row of column.rows) {
                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.is_mdf && row.fillings) {

                        if (row.merged) {
                            this.door_m2 = row.height * row.width;

                            for(const mr of row.merged_with) {
    
                                for(const cr of column.rows) {
                                    if (cr.row_id == mr.row_id) {
                                        this.door_m2 = this.door_m2 + (Number(cr.height) * Number(cr.width));
                                    }
                                }
                            }
    
                            this.doors_m2 = this.doors_m2 + (this.door_m2 / 10000);
                            this.door_m2 = 0;
                        }
                        else {
                            this.doors_m2 = this.doors_m2 + ((row.height * row.width) / 10000);
                        }

                        // if (row.filling.style == 4) {
                            // this.counter = this.counter + (this.doors_m2 * Number(row.filling.price_m2));
                        // }
                        // else {
                            // this.counter = this.counter + Number(row.filling.price_items);
                        // }
                        this.counter = this.counter + (this.doors_m2 * Number(row.filling.price_m2));
                        

                    }
                }
                this.doors_m2 = 0;
                
            }
        }

        return this.counter;
    }

    getMilledKnobsPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {

                if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5 || row.outer == 6) {
                    if (row.handleType == 6 && row.is_mdf) {

                        this.counter = this.counter + Number(row.milled_knob.price);
    
                    }
                }
                
                
            });
        });

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                if (row.handle_type == 3 && row.panel_type == 2) {
                    this.counter = this.counter + Number(row.milled_knob.price);
                }

            });
        });

        return this.counter;
    }

    getAdjustableHeightShelfPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                
                if (row.inner == 10) {
                    this.counter = this.counter + 5;
                }
            });
        });

        return this.counter;
    }

  
    getShelvesRailsPrice() {
        let price = 0;
        if (this.fs.furniture.quality == 1) {
            price = 4;
        }
        else if (this.fs.furniture.quality == 2) {
            price = 11;
        }
        else if (this.fs.furniture.quality == 3) {
            price = 26;
        }

        return price;
    }

    getPullOutShelfPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                
                if (row.inner == 11) {
                    this.counter = this.counter + 5 + this.getShelvesRailsPrice();
                }
            });
        });

        return this.counter;
    }

    getRetractableHangerPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                
                if (row.inner == 8) {
                    this.counter = this.counter + 9.98;
                }
            });
        });

        return this.counter;
    }

    getPantographPrice() {
        this.counter = 0;
        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {
                
                if (row.inner == 9) {
                    this.counter = this.counter + 39.13;
                }
            });
        });

        return this.counter;
    }

    getSlidingDoorsLMDPPrice() {
       
        this.counter = 0;

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                this.door_m2 = 0;

                if (row.panel_type == 0) {

                    if (row.width < 50) {
                        this.door_m2 = this.door_m2 + 1.5;
                    }
                    else if (row.width >= 50 && row.width < 100) {
                        this.door_m2 = this.door_m2 + 3;
                    }
                    else if (row.width >= 100) {
                        this.door_m2 = this.door_m2 + 6;
                    }

                    this.counter = this.counter + (this.door_m2 * Number(row.panel.price));
                }


            });
        });

        return this.counter;
    }
    getRailsOneDoorsPrice() {

        this.slidingDoorsGroup = [];
        this.slidingDoorsLmdp = [];
        this.counter = 0;
        this.slidingDoorsLmdpSame = [];

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                if (row.panel_type == 0) {
                    this.slidingDoorsGroup.push(row);
                }

            });
        });

        // distinctThings = this.slidingDoorsGroup.filter((thing, i, arr) => {
            // return arr.indexOf(arr.find(t => t.panel_id === thing.panel_id)) === i;
        //});




        this.slidingDoorsLmdpSame = this.slidingDoorsGroup.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => t.panel_id === thing.panel_id)) === i;
        });

        for(const row of this.slidingDoorsLmdpSame) {
            if (row.panel_type == 0) {
                let totalHeight = 0;

                for(const row2 of this.slidingDoorsGroup) {
                    if (row.panel_id == row2.panel_id) {
                        totalHeight = totalHeight + row.height;
                    }
                };
    
                this.slidingDoorsLmdp.push({
                    name: row.panel.code,
                    price: Number(row.panel.price),
                    height: totalHeight,
                    width: row.width,
                    rounded: Number(Math.ceil(totalHeight / this.fs.furniture.height)),
                    m2: this.getRoundedOneDoorM2(row.width),
                    finalPrice: Number(row.panel.price) * Number(this.getRoundedOneDoorM2(row.width)) * Number(Math.ceil(totalHeight / this.fs.furniture.height))
                });
    
                totalHeight = 0;
            }
            
        }

        for(const row of this.slidingDoorsLmdp) {
            if (row.panel_type == 0) {
                this.counter = this.counter + row.finalPrice;
            }
            
        }

        return this.counter;
    }

    // ORIGINAL
    getRailsOneDoorsPriceORIGINAL() {

        this.counter = 0;

        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {

                this.door_m2 = 0;

                if (row.panel_type == 0) {

                    if (row.width < 50) {
                        this.door_m2 = this.door_m2 + 1.5;
                    }
                    else if (row.width >= 50 && row.width < 100) {
                        this.door_m2 = this.door_m2 + 3;
                    }
                    else if (row.width >= 100) {
                        this.door_m2 = this.door_m2 + 6;
                    }

                    this.counter = this.counter + (this.door_m2 * Number(row.panel.price));
                }


            });
        });

        return this.counter;
    }

    getMirrorPrice() {
        this.counter = 0;
        this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {
            door.divideRows.forEach((row: IDivideRows) => {
                if (row.panel_type == 1) {
                    this.counter = this.counter + ((row.width * row.height / 10000) * Number(row.mirror.price));
                }
            });
        });

        return this.counter;
    }

    // fix START  ------------------------------------------------------------------------------------------

    getRailsPrice() {
        
        // Kita logika...
        if (this.fs.furniture.rails == 1) {
            return 0;
        }

        // Paslepti bėgeliai
        else if (this.fs.furniture.rails == 2) {
            if (this.fs.furniture.width <= 150) {
                return 76.12;
            }
            else if (this.fs.furniture.width <= 300) {
                return 152.24;
            }
            else if (this.fs.furniture.width <= 450) {
                return 228.36;
            }
            else if (this.fs.furniture.width <= 600) {
                return 304.48;
            }
        }

        // Matosi viršutinis
        else if (this.fs.furniture.rails == 3) {
            if (this.fs.furniture.width <= 150) {
                return 50.13;
            }
            else if (this.fs.furniture.width <= 300) {
                return 100.26;
            }
            else if (this.fs.furniture.width <= 450) {
                return 150.39;
            }
            else if (this.fs.furniture.width <= 600) {
                return 200.52;
            }
        }

        else {
            return 0;
        }

    }

    getWheelsPrice() {
        // Kita logika...
        if (this.fs.furniture.rails == 1) {
            return 0;
        }

        // Paslepti bėgeliai
        else if (this.fs.furniture.rails == 2) {
            if (this.fs.furniture.slidingDoorsCount == 2) { return 150.46; }
            else if (this.fs.furniture.slidingDoorsCount == 3) { return 215.22; }
            else if (this.fs.furniture.slidingDoorsCount == 4) { return 300.92; }
            else if (this.fs.furniture.slidingDoorsCount == 5) { return 365.68; }
            else if (this.fs.furniture.slidingDoorsCount == 6) { return 451.38; }
            else if (this.fs.furniture.slidingDoorsCount == 7) { return 516.14; }
            else if (this.fs.furniture.slidingDoorsCount == 8) { return 601.84; }
            else if (this.fs.furniture.slidingDoorsCount == 9) { return 666.6; }
            else if (this.fs.furniture.slidingDoorsCount == 10) { return 752.3; }
            else if (this.fs.furniture.slidingDoorsCount == 11) { return 817.06; }
            else if (this.fs.furniture.slidingDoorsCount == 12) { return 902.76; }
            else if (this.fs.furniture.slidingDoorsCount == 13) { return 967.52; }
            else if (this.fs.furniture.slidingDoorsCount == 14) { return 1053.22; }
            else if (this.fs.furniture.slidingDoorsCount == 15) { return 1117.98; }
        }

        // Matosi viršutinis
        else if (this.fs.furniture.rails == 3) {
            if (this.fs.furniture.slidingDoorsCount == 2) { return 120.5; }
            else if (this.fs.furniture.slidingDoorsCount == 3) { return 180.75; }
            else if (this.fs.furniture.slidingDoorsCount == 4) { return 241; }
            else if (this.fs.furniture.slidingDoorsCount == 5) { return 301.25; }
            else if (this.fs.furniture.slidingDoorsCount == 6) { return 361.5; }
            else if (this.fs.furniture.slidingDoorsCount == 7) { return 421.75; }
            else if (this.fs.furniture.slidingDoorsCount == 8) { return 482; }
            else if (this.fs.furniture.slidingDoorsCount == 9) { return 542.25; }
            else if (this.fs.furniture.slidingDoorsCount == 10) { return 602.5; }
            else if (this.fs.furniture.slidingDoorsCount == 11) { return 662.75; }
            else if (this.fs.furniture.slidingDoorsCount == 12) { return 723; }
            else if (this.fs.furniture.slidingDoorsCount == 13) { return 783.25; }
            else if (this.fs.furniture.slidingDoorsCount == 14) { return 843.5; }
            else if (this.fs.furniture.slidingDoorsCount == 15) { return 903.75; }
        }

        else {
            return 0;
        }
    }

    getRodsPrice() {
        // Paslepti bėgeliai    // Matosi viršutinis
        if (this.fs.furniture.rails == 1 || this.fs.furniture.rails == 2 || this.fs.furniture.rails == 3) {
            this.counter = 0;

            if (!this.fs.furniture.sliding_door_profiles) {
                this.fs.furniture.slidingDoors.forEach((door: ISlidingDoorItem) => {

                    /* Old 2024-10-01
                    if (door.divideRows.length == 1) {
    
                        this.doorHasMirror = false;
                        for(const divideRow of door.divideRows) {
                            this.doorHasMirror = divideRow.panel_type == 1 ? true : false;
                        };
    
                        if (!this.doorHasMirror) {
                            if (door.width <= 50) {
                                this.counter = this.counter + 23.86;
                            }
                            else if (door.width <= 150) {
                                this.counter = this.counter + 47.72;
                            }
                        }
    
                        
                    }
                    */
    
                    // sliding_door_profiles
                  
    
                        this.doorHasMirror = false;
                        for(const divideRow of door.divideRows) {
                            this.doorHasMirror = divideRow.panel_type == 1 ? true : false;
                        };
    
                        if (!this.doorHasMirror) {
                            if (door.width <= 50) {
                                this.counter = this.counter + 23.86;
                            }
                            else if (door.width <= 150) {
                                this.counter = this.counter + 47.72;
                            }
                        }
    
                        
                   
                    
                });
            }
            

            return this.counter;
        }

        else {
            return 0;
        }
    }

    getBrakesPrice() {
        // Kita logika...
        if (this.fs.furniture.rails == 1) {
            if (this.fs.furniture.slidingDoorsCount == 2) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 34; }
                else if (this.fs.furniture.quality == 3) { return 68; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 3) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 132; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 4) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68.00; }
                else if (this.fs.furniture.quality == 3) { return 196; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 5) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 260; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 6) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 324; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 7) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 388; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 8) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 452; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 9) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 516; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 10) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 580; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 11) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 644; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 12) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 708; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 13) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 772; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 14) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 836; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 15) { 
                if (this.fs.furniture.quality == 1) { return 0; }
                else if (this.fs.furniture.quality == 2) { return 68; }
                else if (this.fs.furniture.quality == 3) { return 900; }
            }
        }

        // Paslepti bėgeliai
        else if (this.fs.furniture.rails == 2) {
            if (this.fs.furniture.slidingDoorsCount == 2) { 
                if (this.fs.furniture.quality == 1) { return 5.12; }
                else if (this.fs.furniture.quality == 2) { return 43.64; }
                else if (this.fs.furniture.quality == 3) { return 87.28; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 3) { 
                if (this.fs.furniture.quality == 1) { return 7.68; }
                else if (this.fs.furniture.quality == 2) { return 46.2; }
                else if (this.fs.furniture.quality == 3) { return 191.82; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 4) { 
                if (this.fs.furniture.quality == 1) { return 10.24; }
                else if (this.fs.furniture.quality == 2) { return 48.48; }
                else if (this.fs.furniture.quality == 3) { return 296.36; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 5) { 
                if (this.fs.furniture.quality == 1) { return 12.8; }
                else if (this.fs.furniture.quality == 2) { return 51.04; }
                else if (this.fs.furniture.quality == 3) { return 400.9; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 6) { 
                if (this.fs.furniture.quality == 1) { return 15.36; }
                else if (this.fs.furniture.quality == 2) { return 53.6; }
                else if (this.fs.furniture.quality == 3) { return 505.44; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 7) { 
                if (this.fs.furniture.quality == 1) { return 17.92; }
                else if (this.fs.furniture.quality == 2) { return 56.16; }
                else if (this.fs.furniture.quality == 3) { return 609.98; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 8) { 
                if (this.fs.furniture.quality == 1) { return 20.48; }
                else if (this.fs.furniture.quality == 2) { return 58.72; }
                else if (this.fs.furniture.quality == 3) { return 714.52; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 9) { 
                if (this.fs.furniture.quality == 1) { return 23.04; }
                else if (this.fs.furniture.quality == 2) { return 58.72; }
                else if (this.fs.furniture.quality == 3) { return 714.52; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 10) { 
                if (this.fs.furniture.quality == 1) { return 25.6; }
                else if (this.fs.furniture.quality == 2) { return 63.84; }
                else if (this.fs.furniture.quality == 3) { return 923.6; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 11) { 
                if (this.fs.furniture.quality == 1) { return 28.16; }
                else if (this.fs.furniture.quality == 2) { return 66.4; }
                else if (this.fs.furniture.quality == 3) { return 1028.14; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 12) { 
                if (this.fs.furniture.quality == 1) { return 30.72; }
                else if (this.fs.furniture.quality == 2) { return 68.96; }
                else if (this.fs.furniture.quality == 3) { return 1132.68; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 13) { 
                if (this.fs.furniture.quality == 1) { return 33.28; }
                else if (this.fs.furniture.quality == 2) { return 71.52; }
                else if (this.fs.furniture.quality == 3) { return 1237.22; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 14) { 
                if (this.fs.furniture.quality == 1) { return 35.84; }
                else if (this.fs.furniture.quality == 2) { return 74.08; }
                else if (this.fs.furniture.quality == 3) { return 1341.76; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 15) { 
                if (this.fs.furniture.quality == 1) { return 38.4; }
                else if (this.fs.furniture.quality == 2) { return 76.64; }
                else if (this.fs.furniture.quality == 3) { return 1446.3; }
            }
        }

        // Matosi viršutinis
        else if (this.fs.furniture.rails == 3) {
            if (this.fs.furniture.slidingDoorsCount == 2) { 
                if (this.fs.furniture.quality == 1) { return 18.16; }
                else if (this.fs.furniture.quality == 2) { return 42.48; }
                else if (this.fs.furniture.quality == 3) { return 84.96; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 3) { 
                if (this.fs.furniture.quality == 1) { return 27.24; }
                else if (this.fs.furniture.quality == 2) { return 51.56; }
                else if (this.fs.furniture.quality == 3) { return 127.44; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 4) { 
                if (this.fs.furniture.quality == 1) { return 36.32; }
                else if (this.fs.furniture.quality == 2) { return 60.64; }
                else if (this.fs.furniture.quality == 3) { return 169.92; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 5) { 
                if (this.fs.furniture.quality == 1) { return 45.4; }
                else if (this.fs.furniture.quality == 2) { return 69.72; }
                else if (this.fs.furniture.quality == 3) { return 212.4; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 6) { 
                if (this.fs.furniture.quality == 1) { return 54.48; }
                else if (this.fs.furniture.quality == 2) { return 78.8; }
                else if (this.fs.furniture.quality == 3) { return 254.88; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 7) { 
                if (this.fs.furniture.quality == 1) { return 63.56; }
                else if (this.fs.furniture.quality == 2) { return 87.88; }
                else if (this.fs.furniture.quality == 3) { return 297.36; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 8) { 
                if (this.fs.furniture.quality == 1) { return 72.64; }
                else if (this.fs.furniture.quality == 2) { return 96.96; }
                else if (this.fs.furniture.quality == 3) { return 339.84; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 9) { 
                if (this.fs.furniture.quality == 1) { return 81.72; }
                else if (this.fs.furniture.quality == 2) { return 106.04; }
                else if (this.fs.furniture.quality == 3) { return 382.32; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 10) { 
                if (this.fs.furniture.quality == 1) { return 90.8; }
                else if (this.fs.furniture.quality == 2) { return 115.12; }
                else if (this.fs.furniture.quality == 3) { return 424.8; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 11) { 
                if (this.fs.furniture.quality == 1) { return 99.88; }
                else if (this.fs.furniture.quality == 2) { return 124.2; }
                else if (this.fs.furniture.quality == 3) { return 467.28; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 12) { 
                if (this.fs.furniture.quality == 1) { return 108.96; }
                else if (this.fs.furniture.quality == 2) { return 133.28; }
                else if (this.fs.furniture.quality == 3) { return 509.76; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 13) { 
                if (this.fs.furniture.quality == 1) { return 118.04; }
                else if (this.fs.furniture.quality == 2) { return 142.36; }
                else if (this.fs.furniture.quality == 3) { return 552.24; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 14) { 
                if (this.fs.furniture.quality == 1) { return 127.12; }
                else if (this.fs.furniture.quality == 2) { return 151.44; }
                else if (this.fs.furniture.quality == 3) { return 594.72; }
            }
            else if (this.fs.furniture.slidingDoorsCount == 15) { 
                if (this.fs.furniture.quality == 1) { return 136.2; }
                else if (this.fs.furniture.quality == 2) { return 160.52; }
                else if (this.fs.furniture.quality == 3) { return 637.2; }
            }
        }

        else {
            return 0;
        }
    }


    getMeasurmentPrice() {
        if (this.fs.furniture.installation == 1 || this.fs.furniture.installation == 15) {
            return 0;
        }
        else if (this.fs.furniture.installation == 9 || this.fs.furniture.installation == 10 || this.fs.furniture.installation == 11 || this.fs.furniture.installation == 12 || this.fs.furniture.installation == 13 || this.fs.furniture.installation == 14 || this.fs.furniture.installation == 16) {
            if (this.fs.furniture.collect_myself) {
                return 0;
            }
            else {
                return 57.14;
            }
        }
        else if (this.fs.furniture.installation == 8 || this.fs.furniture.installation == 2 || this.fs.furniture.installation == 3 || this.fs.furniture.installation == 4 || this.fs.furniture.installation == 5 || this.fs.furniture.installation == 6) {
            if (this.fs.furniture.collect_myself) {
                return 0;
            }
            else {
                return 71.43;    
            }
        } 
    }


    // fix END ---------------------------------------------------------------

    

    getPrimaryPrice() {
       
            this.counter = 0;
            
            // # 1
            this.counter = this.counter + this.getM2RoundedPrice();

            // # 1.1
            this.counter = this.counter + this.getDoorsOfDifferentLmdpPrice();

            // # 1.2
            // this.counter = this.counter + this.getDrawerPrice();

            // # 2
            this.counter = this.counter + this.getClothesHangersPrice();

            // # 3
            this.counter = this.counter + this.getLegsPrice();

            // # 4
            this.counter = this.counter + this.getRejectsPrice();

            // # 5
            this.counter = this.counter + this.getHandlesPrice();

            // # 6
            this.counter = this.counter + this.getDrawersPrice();

            // # 7
            this.counter = this.counter + this.getDoorsSidePrice();

            // # 8
            this.counter = this.counter + this.getDoorsDownPrice();

            // # 9
            this.counter = this.counter + this.getDoorsUpPrice();

            // # 10
            if (this.fs.furniture.additional.option_1) {
                this.counter = this.counter + this.getRearWallPrice();
            }
            else {
                this.counter = this.counter + 0;
            }

            // # 11
            if (this.fs.furniture.additional.option_2) {
                this.counter = this.counter + 15.00;
            }
            else {
                this.counter = this.counter + 0;
            }
            
            // # 12
            if (this.fs.furniture.additional.option_3) {
                this.counter = this.counter + 20.00;
            }
            else {
                this.counter = this.counter + 0;
            }

            // # 13
            this.counter = this.counter + this.getMeasurmentPrice();

            // # 14
            this.counter = this.counter + (this.fs.furniture.width / 100 * 16.5);

            // # 15
            this.counter = this.counter + (this.fs.furniture.width / 100 * 18.15);

            // # 16
            // this.counter = this.counter + this.getAssemblyPrice();

            // # 17
            this.counter = this.counter + this.getEdgePrice();

            // # 18
            this.counter = this.counter + this.getDoorsHingesPrice();

            // # 19
            this.counter = this.counter + this.getGluePrice();

            // # 20
            this.counter = this.counter + this.getEdgeThickPrice();

            // # 30
            this.counter = this.counter + this.getMirrorPrice();
            
            // # 31
            this.counter = this.counter + this.getRailsPrice();

            // # 32
            this.counter = this.counter + this.getWheelsPrice();

            // # 33
            this.counter = this.counter + this.getBrakesPrice();

            // # 34
            this.counter = this.counter + this.getRodsPrice();

            // # 35
            // this.counter = this.counter + this.getSlidingDoorsPrice();
            
            // # 36
            this.counter = this.counter + this.getKnobPrice();

            // # 37
            this.counter = this.counter + this.getKnobDividePrice();

            // # 38
            // this.counter = this.counter + this.getRailsOneDoorsPrice();
            this.counter = this.counter + this.getSlidingDoorsLMDPPrice();

            // # 39
            this.counter = this.counter + 58.47;

            // # 40
            this.counter = this.counter + this.getMDFPrice();

            // # 41

            this.counter = this.counter + this.getFillingsPrice();

            // # 42
            this.counter = this.counter + this.getMilledKnobsPrice();

            // # 43
            this.counter = this.counter + this.getMaterialFillingsPrice();

            this.counter = this.counter + this.getSlidingDoorsMDFPrice();


            this.counter = this.counter + this.getAdjustableHeightShelfPrice();

            this.counter = this.counter + this.getPullOutShelfPrice();

            this.counter = this.counter + this.getRetractableHangerPrice();

        
            this.counter = this.counter + this.getPantographPrice();


            if (this.fs.furniture.material_different_sides) {
                if(!this.isLeftMaterialSame()) {
                    this.counter = this.counter + this.getMaterialLeftSidePrice();
                }
    
                if(!this.isRightMaterialSame()) {
                    this.counter = this.counter + this.getMaterialRightSidePrice();
                }
    
                if(!this.isTopMaterialSame()) {
                    this.counter = this.counter + this.getMaterialTopSidePrice();
                }
    
                if(!this.isBottomMaterialSame()) {
                    this.counter = this.counter + this.getMaterialBottomSidePrice();
                }
            }

            

            // # Final
            return this.counter;

    }


    getProjectionPrice() {
        if (this.fs.furniture.collect_myself) {
            return this.getPrimaryPrice() * 0.2;
        }
        else {
            return this.getPrimaryPrice() * 0.15;
        }
        
    }

    getAdministrationPrice() {
        if (this.fs.furniture.collect_myself) {
            return this.getPrimaryPrice() * 0.15;
        }
        else {
            return this.getPrimaryPrice() * 0.25;   
        }
    }

    getPrice(f: IFurnitureItem) {
        if (f) {
            this.counter = 0;
            this.counter = this.getPrimaryPrice() + this.getProjectionPrice() + this.getAdministrationPrice();
            return this.counter;
        }
    }

}