import './FiRequestDialog.scss';

import * as angular from 'angular';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';
import { FiPrice } from '../../services/Price';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiRequestDialog.html'),
    bindings: {

    },
})
export class FiRequestDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
        'fiPrice',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
        private ps: FiPrice,
    ) {
        //
    }

    requestSent: boolean;
    loading: boolean;

    updateLink: string;
    item_id: string;
    item_category: number;

    requestName: string;
    requestLastName: string;
    requestPhone: string;
    requestEmail: string;
    requestComments: string;
    requestConsultation: boolean;

    itemLink: string;

    $onInit() {
        this.item_id = 'FIT' + Math.floor(Math.random() * 89999999 + 10000000);
    }

    close() {
        this.$mdDialog.cancel();
    }

    sendRequest() {
        
        this.loading = true;
        this.sendToHubspot();
        this.saveItem();
       
    }

    saveItem() {

        this.updateLink = "https://www.fitit.lt/db/new-furniture.php";
        this.item_category = 2;
        this.createItem();

    }

    sendToHubspot() {
        const current = new Date();
        const timestamp = current.getTime();

        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/25831285/59f26b76-649d-4a48-8228-6ad096bfca13'
        var data = {
        "submittedAt": timestamp,
        "fields": [
            {
                "objectTypeId": "0-1",
                "name": "email",
                "value": this.requestEmail
            },
            {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": this.requestName
            },
            {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": this.requestLastName
            },
            {
                "objectTypeId": "0-1",
                "name": "message",
                // "value": this.requestComments + '\n' + (this.requestConsultation === true ? 'Konsultacija reikalinga' : 'Konsultacijos nereikia')
                "value": this.requestComments + '\n' + (this.requestConsultation === true ? 'Konsultacija reikalinga' : 'Konsultacijos nereikia') + '\n' + 'https://www.fitit.lt/baldai/' + this.item_id
            },
            {
                "objectTypeId": "0-1",
                "name": "phone",
                "value": this.requestPhone
            },
            {
                "objectTypeId": "0-1",
                "name": "salutation",
                "value": 'Užklausos tipas: Išsaugotas baldas'
            }
        ],
        "context": {
            // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            "pageUri": "www.fitit.lt/konfiguratorius",
            "pageName": "Užklausos tipas: Išsaugotas baldas"
        },
        "legalConsentOptions":{ // Include this object when GDPR options are enabled
            "consent":{
            "consentToProcess":true,
            "text":"I agree to allow Example Company to store and process my personal data.",
            "communications":[
                {
                "value":true,
                "subscriptionTypeId":999,
                "text":"I agree to receive marketing communications from Example Company."
                }
            ]
            }
        }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                // alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                // alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 
        
        xhr.send(final_data);
    }

    createItem() {
        // Creating item
        this.$http.post(this.updateLink, { 

            item_id: this.item_id,
            title: this.fs.furniture.title,
            orderby: this.fs.furniture.orderby,
            price: this.ps.getPrice(this.fs.furniture),
            extra_description_1: this.fs.furniture.extra_description_1,
            extra_description_2: this.fs.furniture.extra_description_2,
            extra_description_3: this.fs.furniture.extra_description_3,
            extra_description_4: this.fs.furniture.extra_description_4,
            extra_description_5: this.fs.furniture.extra_description_5,
            extra_price_1: this.fs.furniture.extra_price_1,
            extra_price_2: this.fs.furniture.extra_price_2,
            extra_price_3: this.fs.furniture.extra_price_3,
            extra_price_4: this.fs.furniture.extra_price_4,
            extra_price_5: this.fs.furniture.extra_price_5,
            price_from: this.fs.furniture.price_from,
            description: this.fs.furniture.description,
            category: this.item_category,
            width: this.fs.furniture.width,
            height: this.fs.furniture.height,
            depth: this.fs.furniture.depth,
            additional_1: this.fs.furniture.additional.option_1,
            additional_2: this.fs.furniture.additional.option_2,
            additional_3: this.fs.furniture.additional.option_3,
            additional_2_height: this.fs.furniture.additional_2_height,
            additional_2_depth: this.fs.furniture.additional_2_depth,
            additional_2_unknow: this.fs.furniture.additional_2_unknow,
            material_is_mdf: this.fs.furniture.material_is_mdf,
            material_has_fillings: this.fs.furniture.material_has_fillings,
            material_mdf_id: this.fs.furniture.material_mdf_id,
            material_mdf_thickness: this.fs.furniture.material_mdf_thickness,
            material_mdf_gloss: this.fs.furniture.material_mdf_gloss,
            material_mdf_filling_id: this.fs.furniture.material_mdf_filling_id,
            type: this.fs.furniture.type,
            quality: this.fs.furniture.quality,
            installation: this.fs.furniture.installation,
            plinth_type: this.fs.furniture.plinth.type,
            plinth_height: this.fs.furniture.plinth.height,
            plinth_legs_id: this.fs.furniture.plinth.legs_id,
            knob_id: this.fs.furniture.knob_id,
            material_id: this.fs.furniture.material.id,
            material_type: this.fs.furniture.material_type,
            edges: this.fs.furniture.edges,
            columnsCount: this.fs.furniture.columnsCount,
            entresol: this.fs.furniture.entresol,
            entresolHeight: this.fs.furniture.entresolHeight,
            rails: this.fs.furniture.rails,
            doors_count: this.fs.furniture.doors_count,
            with_drawers: this.fs.furniture.with_drawers,
            draft: false,
            with_hangers: this.fs.furniture.with_hangers,
            visible: false,
            modified: false,
            show_in_fp: false,
            slidingDoorsCount: this.fs.furniture.slidingDoorsCount,
            material_different_sides: this.fs.furniture.material_different_sides,
            material_ls_lmdp_id: this.fs.furniture.material_ls_lmdp_id,
            material_rs_lmdp_id: this.fs.furniture.material_rs_lmdp_id,
            material_ts_lmdp_id: this.fs.furniture.material_ts_lmdp_id,
            material_bs_lmdp_id: this.fs.furniture.material_bs_lmdp_id,
            material_ls_type: this.fs.furniture.material_ls_type,
            material_rs_type: this.fs.furniture.material_rs_type,
            material_ts_type: this.fs.furniture.material_ts_type,
            material_bs_type: this.fs.furniture.material_bs_type,
            material_ls_is_mdf: this.fs.furniture.material_ls_is_mdf,
            material_rs_is_mdf: this.fs.furniture.material_rs_is_mdf,
            material_ts_is_mdf: this.fs.furniture.material_ts_is_mdf,
            material_bs_is_mdf: this.fs.furniture.material_bs_is_mdf,
            material_ls_mdf_id: this.fs.furniture.material_ls_mdf_id,
            material_rs_mdf_id: this.fs.furniture.material_rs_mdf_id,
            material_ts_mdf_id: this.fs.furniture.material_ts_mdf_id,
            material_bs_mdf_id: this.fs.furniture.material_bs_mdf_id,
            material_ls_mdf_gloss: this.fs.furniture.material_ls_mdf_gloss,
            material_rs_mdf_gloss: this.fs.furniture.material_rs_mdf_gloss,
            material_ts_mdf_gloss: this.fs.furniture.material_ts_mdf_gloss,
            material_bs_mdf_gloss: this.fs.furniture.material_bs_mdf_gloss,
            material_ls_mdf_has_fillings: this.fs.furniture.material_ls_mdf_has_fillings,
            material_rs_mdf_has_fillings: this.fs.furniture.material_rs_mdf_has_fillings,
            material_ts_mdf_has_fillings: this.fs.furniture.material_ts_mdf_has_fillings,
            material_bs_mdf_has_fillings: this.fs.furniture.material_bs_mdf_has_fillings,
            material_ls_mdf_filling_id: this.fs.furniture.material_ls_mdf_filling_id,
            material_rs_mdf_filling_id: this.fs.furniture.material_rs_mdf_filling_id,
            material_ts_mdf_filling_id: this.fs.furniture.material_ts_mdf_filling_id,
            material_bs_mdf_filling_id: this.fs.furniture.material_bs_mdf_filling_id,
            sliding_door_profiles: this.fs.furniture.sliding_door_profiles,
            collect_myself: this.fs.furniture.collect_myself,
            wall_height: this.fs.furniture.wall_height,
            wall_width: this.fs.furniture.wall_width,
            wall_depth: this.fs.furniture.wall_depth,
            wall_alignment_left: this.fs.furniture.wall_alignment_left,
            wall_alignment_right: this.fs.furniture.wall_alignment_right,
            wall_alignment_top: this.fs.furniture.wall_alignment_top,
            wall_alignment_bottom: this.fs.furniture.wall_alignment_bottom

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });


        // Adding columns
        for(const column of this.fs.furniture.columns) {

            this.$http.post("https://www.fitit.lt/db/new-furniture-columns.php", { 
                item_id: this.item_id,
                order_no: column.order_no,
                column_id: column.column_id,
                rows_count: column.rowsCount,
                width: column.width,
                height: column.height,
                outer_height: column.outer_height,
                inner_width: column.inner_width

            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) { 
                    //
            });

            for(const row of column.rows) {
                this.$http.post("https://www.fitit.lt/db/new-furniture-rows.php", { 
                    column_id: column.column_id,
                    order_no: row.order_no,
                    row_id: row.row_id,
                    width: column.width,
                    inner_width: column.inner_width,
                    // height: this.fs.furniture.height / column.rowsCount,
                    // inner_height: (this.fs.furniture.height - this.fs.edgeWidth) / column.rowsCount,
                    height: row.height,
                    inner_height: row.inner_height,
                    item_id: this.item_id,
                    panel_id: row.panel_id,
                    handle_id: row.handle_id,
                    milled_knob_id: row.milled_knob_id,
                    handle_internal_id: row.handle_internal_id,
                    handle_type: row.handleType,
                    inner_type: row.inner,
                    outer_type: row.outer,
                    merged: row.merged,
                    parent_row_id: row.parent_row_id,
                    panel_type: row.panelType,
                    is_mdf: row.is_mdf,
                    fillings: row.fillings,
                    mdf_id: row.mdf_id,
                    filling_id: row.filling_id,
                    mdf_thickness: row.mdf_thickness,
                    mdf_gloss: row.mdf_gloss


                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });

                if (row.merged_with) {
                    for(const mrow of row.merged_with) {
                        this.$http.post("https://www.fitit.lt/db/new-furniture-merged-row.php", {
                            item_id: this.item_id,
                            row_id: mrow.row_id,
                            parent_row_id: mrow.parent_row_id,
                            row_height: mrow.row_height,
                            row_width: mrow.row_width,
                            order_no: mrow.order_no,
    
                        }, { headers: {'Content-Type': 'application/json'} })
                            .then(function (response) { 
                                //
                        });
                    }
                }
                
            }

        }

        // Adding sliding doors
        for(const door of this.fs.furniture.slidingDoors) {
            this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors.php", { 
                item_id: this.item_id,
                door_id: door.door_id,
                order_no: door.order_no,
                divide: door.divide,
                width: door.width,
                height: door.height
            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) { 
                    //
            });

            for(const row of door.divideRows) {
                this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors-rows.php", { 
                    door_id: door.door_id,
                    row_id: row.row_id,
                    order_no: row.order_no,
                    width: row.width,
                    height: row.height,
                    item_id: this.item_id,
                    panel_id: row.panel_id,
                    mirror_id: row.mirror_id,
                    knob_id: row.knob_id,
                    panel_type: row.panel_type,
                    mdf_gloss: row.mdf_gloss,
                    mdf_id: row.mdf_id,
                    handle_type: row.handle_type,
                    handle_id: row.handle_id,
                    mdf_thickness: row.mdf_thickness

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });
            }
        }

        // Send mail
        this.$http.post("https://www.fitit.lt/db/request-dialog.php", { 
            
                price: this.ps.getPrice(this.fs.furniture),
                requestName: this.requestName,
                requestPhone: this.requestPhone,
                requestEmail: this.requestEmail,
                requestComments: this.requestComments,
                requestConsultation: this.requestConsultation,
                item_id: this.item_id

            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) { 
                    //
            });


        // Success message
        this.loading = false;
        this.requestSent = true;
    }

}