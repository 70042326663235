import './Documentation.scss';

import { Component } from '../../components/Component';
import * as uirouter from 'angular-ui-router';

import * as angular from 'angular';

import { FiSubHeaderService } from '../../components/fiSubHeader/FiSubHeaderService';
import { FiFurnitureService, IDocument, IRowItem, IColumnItem } from '../../components/fiFurniture/FiFurnitureService';
import { FiToastService } from '../../components/fiToast/FiToastService';
// import { AngularCropperjsModule } from 'angular-cropperjs';



@Component({
    controllerAs: 'vm',
    template: require('./Documentation.html'),
    bindings: {
    }
})
export class Documentation {
    static $inject = [
        'fiSubHeaderService',
        '$state',
        'fiFurnitureService',
        '$http',
        'fiToastService',
        '$timeout',
        '$window',
        '$scope',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $state: uirouter.StateService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
        private ts: FiToastService,
        private $timeout: angular.ITimeoutService,
        private $window: angular.IWindowService,
        private $scope: angular.IScope,
    ) {
        
    }

    item_id: string;
    images = [];
    doc = [];

    private user: any;
    admin: boolean;
    loading: boolean;
    editMode: boolean = true;


    pos1: number = 0;
    pos2: number = 0;
    pos3: number = 0;
    pos4: number = 0;

    stop: boolean = false;

    $onInit() {
       
        

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (!this.user || !this.user.admin) {
            this.admin = false;
        }
        else {
            this.admin = true;
        }

        this.fshs.subHeaderIsHidden = true;
        this.fshs.blankPage = true;
        

        this.item_id = this.$state.params['itemId'];
        this.fs.selectFurniture(this.item_id, false);


        this.$http.post("https://www.fitit.lt/db/get-doc-images.php", { 
            item_id: this.item_id,
        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                this.images.push(response.data);
        });

        

        this.$http.post("https://www.fitit.lt/db/get-doc.php", { 
            item_id: this.item_id,
        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                this.doc.push(response.data);
                this.fs.document = this.doc[0][0];

                if (Number(this.fs.document.curves_none) == 1) { this.fs.document.curves_none = true; } else { this.fs.document.curves_none = false; }
                if (Number(this.fs.document.curves_floor) == 1) { this.fs.document.curves_floor = true; } else { this.fs.document.curves_floor = false; }
                if (Number(this.fs.document.curves_ceiling) == 1) { this.fs.document.curves_ceiling = true; } else { this.fs.document.curves_ceiling = false; }
                if (Number(this.fs.document.curves_left_wall) == 1) { this.fs.document.curves_left_wall = true; } else { this.fs.document.curves_left_wall = false; }
                if (Number(this.fs.document.curves_right_wall) == 1) { this.fs.document.curves_right_wall = true; } else { this.fs.document.curves_right_wall = false; }
        });
    }

    // https://www.w3schools.com/howto/howto_js_draggable.asp


    dragMouseDown(e) {
        const elmnt = document.getElementById('dragzone');

        e = e || window.event;
        e.preventDefault();
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;

        document.addEventListener("mouseup", (e) => {
            this.closeDragElement(e, elmnt);
        });

        document.addEventListener("mousemove", (e) => {
            this.elementDrag(e, elmnt);
        });
        
    }


    // https://stackoverflow.com/questions/63516408/how-to-stop-onmouseup-function-inside-mouse-move-event-listener

    elementDrag(e, elmnt) {
        // const elmnt = document.getElementById('dragzone');
        e = e || window.event;
        e.preventDefault();

        this.pos1 = this.pos3 - e.clientX;
        this.pos2 = this.pos4 - e.clientY;
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;

        elmnt.style.top = (elmnt.offsetTop - this.pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - this.pos1) + "px";

      }

      closeDragElement(e, elmnt) {
       
        // elmnt.removeEventListener("mouseup", this.closeDragElement(e, elmnt));
        elmnt.removeEventListener("mousemove", this.elementDrag(e, elmnt));
        
        console.log('abc');
    }
    
    
    cropImage() {
        // const canvas = <HTMLCanvasElement>document.getElementById('mycanvas');
        //const context = canvas.getContext('2d');


        var canvas = <HTMLCanvasElement>document.getElementById('myCanvas');
        var context = canvas.getContext('2d');
        var imageObj = new Image();
        imageObj.src = 'https://www.fitit.lt/documents/14012025154029World-Furniture-Online_39.jpg';
        imageObj.onload = function() {

            const desiredWidth = 250;
            const desiredHeight = 250;
            
            canvas.width = desiredWidth;
            canvas.height = desiredHeight;

            context.drawImage(imageObj, 100, 100, desiredWidth, desiredHeight, 0, 0, desiredWidth, desiredHeight);
            
            console.log(canvas.width, canvas.height);

            
        };

        
    }

    deleteImage(itemId: string, imageName: string) {

        this.loading = true;

        this.$http.post("https://www.fitit.lt/db/delete-doc-photo.php", { 

            itemId: itemId,
            imageName: imageName

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                // console.log(response);
        });


        this.$timeout(() => {

            // this.loading = false;
            location.reload();

        }, 2000);

    }

    getInfo(type: string) {
        if (type == 'type') {
            if (this.fs.furniture.type == 1) { return 'Spinta su varstomomis durimis'; }
            else if (this.fs.furniture.type == 2) { return 'Spinta su stumdomis durimis'; }
            else if (this.fs.furniture.type == 3) { return 'Komoda'; }
            else if (this.fs.furniture.type == 4) { return 'Lentyna'; }
            else if (this.fs.furniture.type == 5) { return 'TV spintelė'; }
            else if (this.fs.furniture.type == 6) { return 'Spintelė'; }
        }
        else if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return 'Laisvai pastatoma'; }
            else if (this.fs.furniture.installation == 2) { return 'Tvirtinama tik prie lubų'; }
            else if (this.fs.furniture.installation == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
            else if (this.fs.furniture.installation == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
            else if (this.fs.furniture.installation == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
            else if (this.fs.furniture.installation == 6) { return 'Tvirtinama prie abiejų sienų'; }
            else if (this.fs.furniture.installation == 7) { return 'Pakabinama'; }
        }
        else if (type == 'plinth') {
            if (this.fs.furniture.plinth.type == 1) { return 'Padas'; }
            else if (this.fs.furniture.plinth.type == 2) { return 'Kojelės'; }
            else if (this.fs.furniture.plinth.type == 3) { return 'Plokštė'; }
            else if (this.fs.furniture.plinth.type == 4) { return 'Be pagrindo'; }
            else if (this.fs.furniture.plinth.type == 5) { return 'Cokolis'; }
        }
        else if (type == 'quality') {
            if (this.fs.furniture.quality == 1) { return 'Ekonominė'; }
            else if (this.fs.furniture.quality == 2) { return 'Premium'; }
            else if (this.fs.furniture.quality == 3) { return 'Premium +'; }
        }
        else if (type == 'edges') {
            if (this.fs.furniture.edges == 1) { return 'Durelės dengia briaunas'; }
            else if (this.fs.furniture.edges == 2) { return 'Matosi visos'; }
            else if (this.fs.furniture.edges == 3) { return 'Matosi viršus ir apačia'; }
            else if (this.fs.furniture.edges == 4) { return 'Matosi viršus'; }
            else if (this.fs.furniture.edges == 5) { return 'Matosi išorinės'; }
            else if (this.fs.furniture.edges == 6) { return 'Be stogelio (matosi šonai)'; }
            else if (this.fs.furniture.edges == 7) { return 'Su stogeliu (matosi visos)'; }
            else if (this.fs.furniture.edges == 8) { return 'Durelės dengia briaunas'; }
            else if (this.fs.furniture.edges == 9) { return 'Matosi šonai'; }
            else if (this.fs.furniture.edges == 10) { return 'Matosi viršus ir šonai'; }
            else if (this.fs.furniture.edges == 11) { return 'Matosi išorinės ir vertikalios'; }
            else if (this.fs.furniture.edges == 12) { return 'Matosi išorinės ir horizontalius'; }
            else if (this.fs.furniture.edges == 13) { return 'Matosi šonai'; }
        }
    }

    getInfoPic(type: string) {
        if (type == 'type') {
            if (this.fs.furniture.type == 1) { return '/img/icons/baldotipas/baldo_tipas_1.jpg'; }
            else if (this.fs.furniture.type == 2) { return '/img/icons/baldotipas/baldo_tipas_2.svg'; }
            else if (this.fs.furniture.type == 3) { return '/img/icons/baldotipas/baldo_tipas_3.jpg'; }
            else if (this.fs.furniture.type == 4) { return '/img/icons/baldotipas/baldo_tipas_4.jpg'; }
            else if (this.fs.furniture.type == 5) { return '/img/icons/baldotipas/baldo_tipas_5.jpg'; }
            else if (this.fs.furniture.type == 6) { return '/img/icons/baldotipas/baldo_tipas_6.jpg'; }
        }
        else if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return '/img/icons/imontavimas/imontavimas_1_active.jpg'; }
            else if (this.fs.furniture.installation == 2) { return '/img/icons/imontavimas/imontavimas_2_active.jpg'; }
            else if (this.fs.furniture.installation == 3) { return '/img/icons/imontavimas/imontavimas_3_active.jpg'; }
            else if (this.fs.furniture.installation == 4) { return '/img/icons/imontavimas/imontavimas_4_active.jpg'; }
            else if (this.fs.furniture.installation == 5) { return '/img/icons/imontavimas/imontavimas_5_active.jpg'; }
            else if (this.fs.furniture.installation == 6) { return '/img/icons/imontavimas/imontavimas_6_active.jpg'; }
            else if (this.fs.furniture.installation == 7) { return '/img/icons/imontavimas/imontavimas_7_active.jpg'; }
        }
        else if (type == 'plinth') {
            if (this.fs.furniture.plinth.type == 1) { return '/img/icons/pagrindas/pagrindas_1_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 2) { return '/img/icons/pagrindas/pagrindas_2_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 3) { return '/img/icons/pagrindas/pagrindas_3_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 4) { return '/img/icons/pagrindas/pagrindas_4_active.jpg'; }
            else if (this.fs.furniture.plinth.type == 5) { return '/img/icons/pagrindas/pagrindas_5_active.jpg'; }
        }
        else if (type == 'quality') {
            if (this.fs.furniture.quality == 1) { return '/img/icons/papildomos_paslaugos/furnituros_klase_1.jpeg'; }
            else if (this.fs.furniture.quality == 2) { return '/img/icons/papildomos_paslaugos/furnituros_klase_2.jpeg'; }
            else if (this.fs.furniture.quality == 3) { return '/img/icons/papildomos_paslaugos/furnituros_klase_3.jpeg'; }
        }
        else if (type == 'edges') {
            if (this.fs.furniture.edges == 1) { return '/img/icons/briaunos/briaunos_1.jpg'; }
            else if (this.fs.furniture.edges == 2) { return '/img/icons/briaunos/briaunos_2.jpg'; }
            else if (this.fs.furniture.edges == 3) { return '/img/icons/briaunos/briaunos_3.jpg'; }
            else if (this.fs.furniture.edges == 4) { return '/img/icons/briaunos/briaunos_4.jpg'; }
            else if (this.fs.furniture.edges == 5) { return '/img/icons/briaunos/briaunos_5.jpg'; }
            else if (this.fs.furniture.edges == 6) { return '/img/icons/briaunos/briaunos_6.jpg'; }
            else if (this.fs.furniture.edges == 7) { return '/img/icons/briaunos/briaunos_7.jpg'; }
            else if (this.fs.furniture.edges == 8) { return '/img/icons/briaunos/briaunos_8.jpg'; }
            else if (this.fs.furniture.edges == 9) { return '/img/icons/briaunos/briaunos_9.jpg'; }
            else if (this.fs.furniture.edges == 10) { return '/img/icons/briaunos/briaunos_10.jpg'; }
            else if (this.fs.furniture.edges == 11) { return '/img/icons/briaunos/briaunos_11.jpg'; }
            else if (this.fs.furniture.edges == 12) { return '/img/icons/briaunos/briaunos_12.jpg'; }
            else if (this.fs.furniture.edges == 13) { return '/img/icons/briaunos/briaunos_13.jpg'; }
        }
    }
    getInstallationPic() {
        if (this.fs.furniture.installation == 1) { return 'imontavimas_1.jpg'; }
        else if (this.fs.furniture.installation == 2) { return 'imontavimas_2.jpg'; }
        else if (this.fs.furniture.installation == 3) { return 'imontavimas_3.jpg'; }
        else if (this.fs.furniture.installation == 4) { return 'imontavimas_4.jpg'; }
        else if (this.fs.furniture.installation == 5) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 6) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 7) { return 'imontavimas_7.jpg'; }
        else if (this.fs.furniture.installation == 8) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 9) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 10) { return 'imontavimas_2.jpg'; }
        else if (this.fs.furniture.installation == 11) { return 'imontavimas_3.jpg'; }
        else if (this.fs.furniture.installation == 12) { return 'imontavimas_4.jpg'; }
        else if (this.fs.furniture.installation == 13) { return 'imontavimas_5.jpg'; }
        else if (this.fs.furniture.installation == 14) { return 'imontavimas_6.jpg'; }
        else if (this.fs.furniture.installation == 15) { return 'imontavimas_7.jpg'; }
        else if (this.fs.furniture.installation == 16) { return 'imontavimas_6.jpg'; }
    }

    getInstallationInfoTitle() {
        if (this.fs.furniture.installationType == 1) { return 'Įmontavimas'; }
        else if (this.fs.furniture.installationType == 2) { return 'Laisvai pastatoma nišoje'; }
        else if (this.fs.furniture.installationType == 3) { return 'Įmontuojama'; }
        else if (this.fs.furniture.installationType == 4) { return 'Pakabinama'; }
    }

    print() {
        this.$window.print()
    }

    getInstallationInfo() {
        if (this.fs.furniture.installation == 1) { return 'Laisvai pastatoma'; }
        else if (this.fs.furniture.installation == 2) { return 'Tvirtinama tik prie lubų'; }
        else if (this.fs.furniture.installation == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
        else if (this.fs.furniture.installation == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
        else if (this.fs.furniture.installation == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
        else if (this.fs.furniture.installation == 6) { return 'Tvirtinama prie abiejų sienų'; }
        else if (this.fs.furniture.installation == 7) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 8) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 9) { return 'Nepasirinkta'; }
        else if (this.fs.furniture.installation == 10) { return 'Iki lubų'; }
        else if (this.fs.furniture.installation == 11) { return 'Iki lubų ir k. sienos'; }
        else if (this.fs.furniture.installation == 12) { return 'Iki lubų ir d. sienos'; }
        else if (this.fs.furniture.installation == 13) { return 'Iki lubų ir abiejų sienų'; }
        else if (this.fs.furniture.installation == 14) { return 'Iki abiejų sienų'; }
        else if (this.fs.furniture.installation == 15) { return 'Tik pakabinama'; }
        else if (this.fs.furniture.installation == 16) { return 'iki abiejų sienų'; }
    }
    getOuterName(type: number) {
        if (type == 0) {
            return 'Be durelių';
        }
        else if (type == 1) {
            return 'Durys (Kairė)';
        }
        else if (type == 2) {
            return 'Durys (Dešinė)';
        }
        else if (type == 3) {
            return 'Durys (Dvigubos)';
        }
        else if (type == 4) {
            return 'Durys (Viršus)';
        }
        else if (type == 5) {
            return 'Durys (Apačia)';
        }
        else if (type == 6) {
            return 'Stalčius';
        }
    }

    goBack() {
        this.$window.location.href = '/baldu-sarasas';
    }

    save() {
        this.$http.post("https://www.fitit.lt/db/save-doc.php", { 

            item_id: this.item_id,
            comments: this.fs.document.comments,
            curves_none: this.fs.document.curves_none,
            curves_floor: this.fs.document.curves_floor,
            curves_ceiling: this.fs.document.curves_ceiling,
            curves_left_wall: this.fs.document.curves_left_wall,
            curves_right_wall: this.fs.document.curves_right_wall,
            obstacle: this.fs.document.obstacle,
            floor: this.fs.document.floor,

        }, { headers: {'Content-Type': 'application/json'} })
        .then(function (response) {
            //
        });

        this.ts.show('Išsaugota');
    }

}
